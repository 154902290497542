/*
* Utility Library.
* @global _ - lodash.
* @global $,jQuery - jquery.
* */
require('expose-loader?_!lodash');
require('expose-loader?$!jquery');
require('expose-loader?jQuery!jquery');

/*
* Expand jQuery.
* */
require('jquery.easing');
require('jquery.cookie');
require('velocity-animate');


/*
* Expand Custom Function.
* */

/*
* Set html5 Element for <ie8.
* */
;((doc, e) => {
  if(!doc) { return; }
  _.each(e, (e) => doc.createElement(e));
  return true;
})(window ? window['document'] : false, require('./_plugin/html5tag'));



/*
* expand setInterval.
* @usage
* var timer = jQuery.fn.timer(()=>{
* });
* timer.stop();
* */
jQuery.fn.timer = require('./_plugin/timer');

/*
* expand requestAnimationFrame.
* @usage
* var timer = jQuery.fn.frame(()=>{
*   ...
* }, 30);
* timer.stop();
* */
jQuery.fn.frame = require('./_plugin/animationframe');

/*
* expand FOnceSubmit
* */
!function($) {
  $.fn.FOnceSubmit = $init();
  return;

  function $init() {
    var
    __isSubmit = false;

    if ('onpageshow' in window) {
      window.addEventListener('pageshow', function(event) {
        try {
          if (event && event.persisted && __isSubmit) {
            __isSubmit = false;
          }
        } catch(e) {}
      });
    }

    return function() {
      if (!__isSubmit) {
        __isSubmit = true;
        return true;
      }
      return false;
    };
  }
}(jQuery);
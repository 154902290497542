module.exports = ((win, doc)=> {
  'use strict';

  const INTERVAL = 32;

  let set_ = setInterval;
  let timerid_ = null;
  let timers_ = {};

  /*
  * @class Timer.
  * */
  class Timer {

    constructor(func){
      this.id = _.uniqueId();
      timers_[this.id] = func;
      if(!timerid_){
        setInterval_();
      }
    }

    stop(){
      if (this.id in timers_) { delete timers_[this.id]; }
      if (_.isEmpty(timers_)) { clearInterval_(); }
    }
  }

  return function(fn){
    return new Timer(fn);
  };


  function setInterval_() {
    timerid_ = set_(function() {
      var k;
      for (k in timers_) { timers_[k].call(); }
    }, INTERVAL);
  }

  function clearInterval_() {
    if (!timerid_) {
      return;
    }
    clearInterval(timerid_);
    timerid_ = null;
  }

})(window, document);

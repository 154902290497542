module.exports = ((win , doc, $) => {
  'use strict';

  /*
  * check requestAnimationFrame API.
  * */
  let frame = win.requestAnimationFrame ||
    win.webkitRequestAnimationFrame ||
    win.mozRequestAnimationFrame ||
    win.oRequestAnimationFrame ||
    win.msRequestAnimationFrame || false;
  let timer;

  $.fn.timer && (timer = $.fn.timer);

  (!timer && !frame) && (frame = setTimeout);

  class Frame{
    /*
    * @constructor
    * @param {Function} fn.
    * @param {number} fps.
    * */
    constructor(fn, fps){
      this.callback = fn;
      this.fps = fps;
      this.stop_flg = false;
      this.init();
    }

    init(){
      let now = $.now();
      let fn = _.bind(loop_, this);

      fn();

      function loop_(){
        if(this.stop_flg){ return; }
        var time = $.now();
        if(time - now < (1000/this.fps)) {
          return frame(fn);
        }
        now = time;
        this.callback();
        frame(fn);
      }
    }
    stop(){
      this.stop_flg = true;
    }
  }

  return function(callback, fps){
    return frame ? new Frame(callback, fps) : timer(callback);
  }

})(window, document, window['jQuery']);

/*
* html5 tag list.
* */
module.exports = [
  'article', 'aside', 'audio',
  'canvas', 'command', 'datalist',
  'details', 'embed', 'figcaption',
  'figure', 'footer', 'header',
  'hgroup', 'keygen', 'mark',
  'menu', 'meter', 'nav',
  'output', 'progress', 'section',
  'source', 'summary', 'time',
  'video', 'rp', 'rt',
  'ruby', 'wbr'
];
